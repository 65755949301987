import { useBasket } from '@/src/packages/components/contexts/basket.context';
import { BasketTotalsDiscountRow } from '@/src/packages/components/ui/Basket/BasketTotals/BasketTotalsDiscountRow';
import { BasketTotalsGrandTotal } from '@/src/packages/components/ui/Basket/BasketTotals/BasketTotalsGrandTotal';
import { BasketTotalsSalesTax } from '@/src/packages/components/ui/Basket/BasketTotals/BasketTotalsSalesTax';
import { BasketTotalsShippingRow } from '@/src/packages/components/ui/Basket/BasketTotals/BasketTotalsShippingRow';
import { BasketTotalsSubtotalRow } from '@/src/packages/components/ui/Basket/BasketTotals/BasketTotalsSubtotalRow';
import { Stack } from '@/src/packages/components/ui/Stack';
import { IFetchCheckout } from '@bitmap/contracts';

export type IBasketTotalsProps = {
  checkout: IFetchCheckout | null;
  showSubtotal?: boolean;
  showDiscount?: boolean;
  showTax?: boolean;
  showShipping?: boolean;
};

export const BasketTotals = ({
  checkout,
  showDiscount = true,
  showShipping = true,
  showSubtotal = true,
  showTax = true,
}: IBasketTotalsProps) => {
  const { removeDiscount } = useBasket();

  return (
    <Stack spaceY={4} direction="vertical">
      {/* {showSubtotal && <BasketTotalsSubtotalRow checkout={checkout} />} */}
      {showDiscount && checkout?.discountCode && (
        <BasketTotalsDiscountRow
          checkout={checkout}
          handleRemoveDiscount={removeDiscount}
        />
      )}
      {/* {showTax && checkout?.salesTaxRate && checkout?.salesTaxRate > 0 && (
        <BasketTotalsSalesTax checkout={checkout} />
      )} */}
      {showShipping && <BasketTotalsShippingRow basket={checkout} />}
      <BasketTotalsGrandTotal checkout={checkout} />
    </Stack>
  );
};

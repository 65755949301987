import Link from 'next/link';
import { paths } from '@/src/packages/routes';
import { BasketDrawerTestIds } from '@/src/packages/components/ui/BasketDrawer/BasketDrawerTestIds.enum';
import { Box } from '@/src/packages/components/ui/Box';
import { getRoute } from '@/src/packages/utils/routes';
import { pathParams } from '@/src/packages/routes/pathParams.enum';
import { useBasket } from '@/src/packages/components/contexts/basket.context';
import { Button } from '@/src/packages/components/ui/Button';

export const BasketDrawerFooter = () => {
  const { basket, isUpdating } = useBasket();

  if (basket?.uuid)
    return (
      <Box testId={BasketDrawerTestIds.BASKET_DRAWER_FOOTER}>
        <Box className="flex flex-col gap-3">
          <Button
            isBlock
            color="black"
            variant="solid"
            size="lg"
            asChild
            isDisabled={isUpdating}
          >
            <Link
              href={getRoute({
                route: paths.CHECKOUT_INDEX,
                params: { [pathParams.CHECKOUT]: basket.uuid },
              })}
            >
              Checkout
            </Link>
          </Button>
        </Box>
      </Box>
    );

  return <></>;
};

import { BasketTestIds } from '@/src/packages/components/ui/Basket/BasketTestIds.enum';
import { BasketTotalRow } from '@/src/packages/components/ui/Basket/BasketTotalRow';
import { Box } from '@/src/packages/components/ui/Box';
import { Flex } from '@/src/packages/components/ui/Flex';
import { StoreConfig } from '@/src/store.config';
import { CommerceUtils, IFetchCheckout } from '@bitmap/contracts';

export const BasketTotalsDiscountRow = ({
  checkout,
  handleRemoveDiscount,
}: {
  checkout: IFetchCheckout | null;
  handleRemoveDiscount(): void;
}) => {
  return (
    <BasketTotalRow
      testId={BasketTestIds.BASKET_TOTALS_DISCOUNT_ROW}
      label={
        <Flex className="items-center">
          <Box>Discount ({checkout?.discountCode?.code})</Box>
          {!checkout?.completedAt && (
            <Box
              marginLeft={2}
              color="weak"
              className="cursor-pointer hover:underline"
              textSize="sm"
              onClick={handleRemoveDiscount}
            >
              Remove
            </Box>
          )}
        </Flex>
      }
      value={`-${CommerceUtils.formatCurrency(
        checkout?.discountGross,
        StoreConfig.default_currency_iso
      )}`}
    />
  );
};

'use client';
import { ReactNode, useEffect } from 'react';
import { Providers } from './LayoutSiteProviders';
import { Scripts } from './LayoutSiteScripts';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { isProduction } from '@/src/packages/utils/isProduction';
import { PreloadedState } from '@reduxjs/toolkit';
import { useVHUnit } from '@bitmap/utils/hooks/use-vh-unit';
import { env } from '@/env.mjs';
import { RootState } from '@/src/packages/store';
import { useGlobalContent } from '@/src/packages/hooks/use-basket-drawer';
import { Document } from '@contentful/rich-text-types';
import { BasketDrawer } from '@/src/packages/components/ui/BasketDrawer';
import { NavigationLocaleSelectorDrawer } from '@/src/packages/components/ui/Navigation/NavigationLocaleSelector';
import { IS_SERVER } from '@/src/packages/components/contexts/store.context';

export const LayoutSite = ({
  children,
  preloadedState,
  warranty,
  currencies,
  shippingDestinations,
  shippingRates,
}: {
  children: ReactNode;
  preloadedState?: PreloadedState<RootState>;
  currencies: any;
  shippingDestinations: any;
  shippingRates: any;
  warranty: Document | undefined;
}) => {
  useVHUnit();

  const { handleSetWarranty } = useGlobalContent();

  useEffect(() => {
    handleSetWarranty(warranty);
  }, [warranty]);

  return (
    <html lang="en" className="scroll-smooth">
      <head>
        <meta
          name="facebook-domain-verification"
          content="5k50ht135q9cymqdeei7txn1s8vwrn"
        />
        <meta
          name="p:domain_verify"
          content="c981d23f562d676fe1e681527f240f14"
        />
        <meta
          name="google-site-verification"
          content="JNMsuQckBfp7TRG0MnfcEsIsedHOj_o-kXkCHmVR9fo"
        />
        <link rel="stylesheet" href="https://use.typekit.net/lsc8qpn.css" />
      </head>
      <body>
        {isProduction && (
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-TRJ9RW6"
              height="0"
              width="0"
              style={{
                display: 'none',
                visibility: 'hidden',
              }}
            />
          </noscript>
        )}
        <Providers
          {...(preloadedState && { preloadedState })}
          shippingDestinations={shippingDestinations}
          shippingRates={shippingRates}
          currencies={currencies}
        >
          <GoogleAnalytics />
          <BasketDrawer />
          <NavigationLocaleSelectorDrawer />
          {children}
        </Providers>
        {isProduction && <Scripts />}
      </body>
    </html>
  );
};

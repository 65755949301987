export enum BasketTestIds {
  'BASKET_EMPTY' = 'BASKET_EMPTY',
  'BASKET_DISCOUNT_FIELD' = 'BASKET_DISCOUNT_FIELD',
  'BASKET_PRODUCT_REMOVE_BUTTON' = 'BASKET_PRODUCT_REMOVE_BUTTON',
  'BASKET_TOTAL_ROW_LABEL' = 'BASKET_TOTAL_ROW_LABEL',
  'BASKET_TOTAL_ROW_VALUE' = 'BASKET_TOTAL_ROW_VALUE',
  'BASKET_TOTALS_DISCOUNT_ROW' = 'BASKET_TOTALS_DISCOUNT_ROW',
  'BASKET_TOTALS_SUBTOTAL_ROW' = 'BASKET_TOTALS_SUBTOTAL_ROW',
  'BASKET_TOTALS_SHIPPING_ROW' = 'BASKET_TOTALS_SHIPPING_ROW',
  'BASKET_TOTALS_GRAND_TOTAL_ROW' = 'BASKET_TOTALS_GRAND_TOTAL_ROW',
}

import { ReactNode } from 'react';
import { PreloadedState } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { Provider as ReactWrapBalancerProvider } from 'react-wrap-balancer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RootState, makeStore } from '@/src/packages/store';
import { StoreProvider } from '@/src/packages/components/contexts/store.context';
import { BasketContextProvider } from '@/src/packages/components/contexts/basket.context';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { CSPostHogProvider } from '@/src/packages/components/contexts/posthog.context';
import { IFetchCurrencies, IFetchShippingRates } from '@bitmap/contracts';
import { IFetchShippingDestinations } from '@bitmap/contracts';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export function Providers({
  children,
  preloadedState,
  currencies,
  shippingDestinations,
  shippingRates,
}: {
  children: ReactNode;
  preloadedState?: PreloadedState<RootState>;
  currencies: IFetchCurrencies;
  shippingDestinations: IFetchShippingDestinations;
  shippingRates: IFetchShippingRates;
}) {
  return (
    <CSPostHogProvider>
      <QueryClientProvider client={queryClient}>
        <RadixTooltip.Provider delayDuration={0}>
          <Provider store={makeStore(preloadedState || {})}>
            <StoreProvider
              currencies={currencies}
              shippingDestinations={shippingDestinations}
              shippingRates={shippingRates}
            >
              <BasketContextProvider>
                <ReactWrapBalancerProvider>
                  {children}
                </ReactWrapBalancerProvider>
              </BasketContextProvider>
            </StoreProvider>
          </Provider>
        </RadixTooltip.Provider>
      </QueryClientProvider>
    </CSPostHogProvider>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { IProductRange } from '@/src/interfaces/generated/contentful';
import { Discount } from '@prisma/client';

type ISettingsSlice = {
  name: string | undefined;
  welcomeDiscount: Pick<Discount, 'type' | 'amount'> | null;
  ranges: IProductRange[];
};

const SettingsSliceInitialState: ISettingsSlice = {
  name: '',
  ranges: [],
  welcomeDiscount: null,
};

export const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState: SettingsSliceInitialState,
  reducers: {},
});

import { BasketTestIds } from '@/src/packages/components/ui/Basket/BasketTestIds.enum';
import { Box, IBoxProps } from '@/src/packages/components/ui/Box';
import { ReactNode } from 'react';

export const BasketTotalRow = ({
  label,
  value,
  testId,
}: {
  label: ReactNode;
  value: ReactNode;
} & Pick<IBoxProps, 'testId'>) => {
  return (
    <Box testId={testId} className="flex w-full font-sans leading-none text-md">
      <Box testId={BasketTestIds.BASKET_TOTAL_ROW_LABEL}>{label}</Box>
      <Box testId={BasketTestIds.BASKET_TOTAL_ROW_VALUE} className="ml-auto">
        {value}
      </Box>
    </Box>
  );
};

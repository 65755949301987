'use client';
import { Basket } from '@/src/packages/components/ui/Basket';
import { BasketDrawerHeader } from '@/src/packages/components/ui/BasketDrawer/BasketDrawerHeader';
import {
  Sheet,
  SheetBody,
  SheetContent,
} from '@/src/packages/components/ui/Sheet';
import { useBasketDrawerStore } from '@/src/packages/hooks/use-basket-drawer';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export const BasketDrawer = () => {
  const { isOpen, handleClose } = useBasketDrawerStore((state) => state);

  const pathname = usePathname();

  useEffect(() => {
    handleClose();
  }, [pathname]);

  return (
    <Sheet open={isOpen} onOpenChange={handleClose}>
      <SheetContent className="w-screen max-w-2xl">
        <BasketDrawerHeader />
        <SheetBody className="flex flex-col overflow-scroll pb-0 lg:pb-0">
          <Basket showShipping={false} showSubtotal={false} showTax={false} />
        </SheetBody>
      </SheetContent>
    </Sheet>
  );
};

'use client';
import { cloneElement, forwardRef, ReactElement, useState } from 'react';
import NextImage, { ImageProps } from 'next/image';

import clsx from 'clsx';
import { Box, IBoxProps } from '../Box';

type IImageProps = Omit<IBoxProps, 'aspectRatio'> &
  Required<Pick<IBoxProps, 'aspectRatio'>> &
  Pick<ImageProps, 'alt' | 'src' | 'fill' | 'width' | 'height' | 'sizes'> & {
    overlay?: ReactElement;
    zoomOnHover?: boolean;
  };

export const Image = forwardRef<HTMLImageElement, IImageProps>((props, ref) => {
  const {
    src,
    alt,
    fill,
    width,
    height,
    overlay,
    className,
    zoomOnHover,
    sizes,
    ...rest
  } = props;

  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={clsx('overflow-hidden', className)}
      position="relative"
      {...rest}
    >
      <Box
        position="relative"
        className={clsx('w-full h-full transition-all duration-300', {
          'scale-105': zoomOnHover && hovered,
        })}
      >
        <NextImage
          ref={ref}
          src={src}
          alt={alt || ''}
          className={clsx('object-cover')}
          sizes={sizes}
          {...(height && { height })}
          {...(width && { width })}
          {...(fill && { fill })}
        />
      </Box>
      {overlay && (
        <Box
          display="flex"
          padding={6}
          position="absolute"
          width="full"
          height="full"
          className="top-0 left-0"
          style={{
            background:
              'linear-gradient(to top, rgba(00, 00, 00, 0.5), transparent)',
          }}
        >
          {cloneElement(overlay)}
        </Box>
      )}
    </Box>
  );
});

Image.displayName = 'Image';

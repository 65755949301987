import { Box } from '@/src/packages/components/ui/Box';
import { BasketProducts } from '@/src/packages/components/ui/Basket/BasketProducts';
import { IFetchCheckout } from '@bitmap/contracts';
import { cn } from '@/src/packages/utils/cn';

export const BasketContents = (props: {
  checkout: IFetchCheckout | null;
  isEditable: boolean;
  isUpdating: boolean;
}) => {
  const { isUpdating, isEditable, checkout } = props;

  return (
    <Box
      className={cn('relative', {
        'pointer-events-none opacity-50': isUpdating,
      })}
    >
      <BasketProducts checkout={checkout} isEditable={isEditable} />
    </Box>
  );
};

import { BasketTestIds } from '@/src/packages/components/ui/Basket/BasketTestIds.enum';
import { Box } from '@/src/packages/components/ui/Box';
import { Flex } from '@/src/packages/components/ui/Flex';
import { IFetchCheckout } from '@bitmap/contracts';

export const BasketTotalsGrandTotal = ({
  checkout,
}: {
  checkout: IFetchCheckout | null;
}) => {
  return (
    <Flex
      textSize="xl"
      className="leading-none"
      testId={BasketTestIds.BASKET_TOTALS_GRAND_TOTAL_ROW}
    >
      <Box>Total</Box>
      <Box className="ml-auto">{checkout?.totalGrossFormatted}</Box>
    </Flex>
  );
};

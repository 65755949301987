import { BasketProduct } from '@/src/packages/components/ui/Basket/BasketProduct';
import { Divider } from '@/src/packages/components/ui/Divider';
import { Stack } from '@/src/packages/components/ui/Stack';
import { IFetchCheckout } from '@bitmap/contracts';

export const BasketProducts = ({
  isEditable,
  checkout,
}: {
  isEditable: boolean;
  checkout: IFetchCheckout | null;
}) => {
  return (
    <>
      {checkout?.lineItems && checkout.lineItems.length > 0 && (
        <Stack spaceY={5} direction="vertical" divider={<Divider className='-mr-4' />}>
          {checkout.lineItems.map((item, i) => {
            return (
              <BasketProduct item={item} key={i} isEditable={isEditable} />
            );
          })}
        </Stack>
      )}
    </>
  );
};

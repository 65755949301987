import { BasketTestIds } from '@/src/packages/components/ui/Basket/BasketTestIds.enum';
import { BasketTotalRow } from '@/src/packages/components/ui/Basket/BasketTotalRow';
import { Box } from '@/src/packages/components/ui/Box';
import { IFetchCheckout } from '@bitmap/contracts';

export const BasketTotalsShippingRow = ({
  basket,
}: {
  basket: IFetchCheckout | null;
}) => {
  return (
    <BasketTotalRow
      testId={BasketTestIds.BASKET_TOTALS_SHIPPING_ROW}
      label="Shipping"
      value={
        basket?.isAddressSet && basket.selectedShippingService ? (
          basket?.shipping > 0 ? (
            basket.shippingFormatted
          ) : (
            'Free'
          )
        ) : (
          <Box color="weak" textSize="sm">
            Calculated in checkout
          </Box>
        )
      }
    />
  );
};

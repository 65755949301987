import { Box } from '@/src/packages/components/ui/Box';
import { Minus, Plus } from 'lucide-react';

const BasketQuantityToggleButton = ({
  type,
  handleClick,
}: {
  type: 'add' | 'remove';
  handleClick(): void;
}) => {
  return (
    <Box
      as="button"
      className="w-8 h-full flex items-center justify-center"
      onClick={handleClick}
    >
      {type === 'add' ? <Plus size={14} /> : <Minus size={14} />}
    </Box>
  );
};

export const BasketQuantityToggle = ({
  quantity,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
}: {
  quantity: number;
  handleIncreaseQuantity(): void;
  handleDecreaseQuantity(): void;
}) => {
  return (
    <Box
      className="inline-flex h-8 rounded-full"
      backgroundColor="inputBackgroundDefault"
      hoverBackgroundColor="inputBackgroundHover"
    >
      <BasketQuantityToggleButton
        type="remove"
        handleClick={handleDecreaseQuantity}
      />
      <Box className="h-full inline-flex items-center leading-none text-sm">
        {quantity}
      </Box>
      <BasketQuantityToggleButton
        type="add"
        handleClick={handleIncreaseQuantity}
      />
    </Box>
  );
};

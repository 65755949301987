import { IFetchCheckout } from '@bitmap/contracts';
import { Box } from '@/src/packages/components/ui/Box';
import { Image } from '@/src/packages/components/ui/Image';
import { Stack } from '@/src/packages/components/ui/Stack';
import { Heading } from '@/src/packages/components/ui/Heading';
import { Flex } from '@/src/packages/components/ui/Flex';
import Link from 'next/link';
import { getRoute } from '@/src/packages/utils/routes';
import { paths } from '@/src/packages/routes';
import { pathParams } from '@/src/packages/routes/pathParams.enum';
import { useBasket } from '@/src/packages/components/contexts/basket.context';
import { BasketQuantityToggle } from '@/src/packages/components/ui/Basket/BasketProductQuantityToggle';

const BasketProductPrice = ({
  lineItem,
}: {
  lineItem: IFetchCheckout['lineItems'][0];
}) => {
  const {
    price: {
      discountNetCents,
      priceOriginalGrossMultipleFormatted,
      priceGrossMultipleFormatted,
    },
  } = lineItem;

  return (
    <Box className="ml-auto flex text-right leading-none">
      {discountNetCents ? (
        <Box className="flex space-x-1">
          <Box className="line-through" color="weak">
            {priceOriginalGrossMultipleFormatted}
          </Box>
          <Box>{priceGrossMultipleFormatted}</Box>
        </Box>
      ) : (
        <>{priceGrossMultipleFormatted}</>
      )}
    </Box>
  );
};

export const BasketProduct = ({
  item,
  isEditable,
}: {
  item: IFetchCheckout['lineItems'][0];
  isEditable: boolean;
}) => {
  const { removeItem, addItem } = useBasket();

  const handleIncreaseQuantity = () => {
    addItem([{ skuId: item.sku.id, quantity: 1 }]);
  };

  const handleDecreaseQuantity = () => {
    removeItem({ lineItemId: item.id, quantity: 1 });
  };

  const handleRemoveItem = () => {
    removeItem({ lineItemId: item.id, quantity: item.quantity });
  };

  return (
    <Box className="flex">
      <Box className="w-20 lg:w-32" marginRight={5}>
        <Image
          src={item.sku.coverUrl || ''}
          alt={item.sku.title}
          aspectRatio="1/1"
          fill
          sizes="300px"
          className="border"
        />
      </Box>
      <Flex className="flex-1 flex-col">
        <Flex marginBottom={4}>
          <Link
            href={getRoute({
              route: paths.PRODUCTS_SINGLE,
              params: {
                [pathParams.PRODUCT_SINGLE]: item.sku.productVariant.slug,
              },
            })}
          >
            <Heading
              as="h3"
              size="h3"
              className="leading-none underline-offset-4 hover:underline"
              fontWeight="medium"
            >
              {item.sku.title}
            </Heading>
          </Link>
          <BasketProductPrice lineItem={item} />
        </Flex>
        <Box className="space-y-3">
          {isEditable ? (
            <Flex className="items-center space-x-2">
              <BasketQuantityToggle
                handleDecreaseQuantity={handleDecreaseQuantity}
                handleIncreaseQuantity={handleIncreaseQuantity}
                quantity={item.quantity}
              />
              <Box
                as="button"
                className="text-text-secondary hover:text-text-primary ml-auto cursor-pointer text-sm leading-none"
                onClick={handleRemoveItem}
              >
                Remove
              </Box>
            </Flex>
          ) : (
            <Box color="weak" textSize="sm">
              Qty: {item.quantity}
            </Box>
          )}

          <Box className="flex">
            <Stack textSize="sm" direction="horizontal" spaceX={3}>
              {item.sku.options.map((option) => {
                return (
                  <Box className="leading-none" color="weak" key={option.id}>
                    {option.productOption.name}: {option.name}
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

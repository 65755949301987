'use client';
import { BasketLoading } from '@/src/packages/components/ui/Basket/BasketLoading';
import { Box } from '@/src/packages/components/ui/Box';
import { BasketContents } from '@/src/packages/components/ui/Basket/BasketContents';
import {
  BasketTotals,
  IBasketTotalsProps,
} from '@/src/packages/components/ui/Basket/BasketTotals';
import { BasketDrawerFooter } from '@/src/packages/components/ui/BasketDrawer/BasketDrawerFooter';
import { BasketEmpty } from '@/src/packages/components/ui/Basket/BasketEmpty';
import { useBasket } from '@/src/packages/components/contexts/basket.context';
import { Country } from '@/src/packages/components/ui/Country';
import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { useLocaleDrawer } from '@/src/packages/hooks/use-basket-drawer';
import { Flex } from '@/src/packages/components/ui/Flex';
import { Button } from '@/src/packages/components/ui/Button';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import { BasketFreeShippingProgress } from '@/src/packages/components/ui/Basket/BasketFreeShippingProgress';
import { Strong } from '@/src/packages/components/ui/Strong';
import { Stack } from '@/src/packages/components/ui/Stack';

export const Basket = (props: Omit<IBasketTotalsProps, 'checkout'>) => {
  const { shippingCountry } = useStoreContext();

  const { isEmpty, basket, isLoading, isUpdating } = useBasket();

  const { handleOpen } = useLocaleDrawer();

  return (
    <>
      {isLoading ? (
        <BasketLoading />
      ) : basket ? (
        <Box className="flex flex-1 flex-col gap-y-4">
          {isEmpty ? (
            <BasketEmpty />
          ) : (
            <>
              <BasketFreeShippingProgress />
              <BasketContents
                checkout={basket}
                isUpdating={isUpdating}
                isEditable={true}
              />
            </>
          )}

          {!isEmpty && (
            <Box className="bg-background-secondary sticky bottom-0 -mx-4 mt-auto flex flex-col gap-4 p-4 lg:-mx-8 lg:p-8">
              <Flex>
                <Box>
                  Shipping to{' '}
                  <Strong>
                    <Country
                      countryIsoCode={
                        basket.address?.countryIsoCode || shippingCountry
                      }
                    />
                  </Strong>
                </Box>
                <Box
                  as="button"
                  onClick={handleOpen}
                  className="text-text-secondary hover:text-text-primary ml-auto cursor-pointer text-sm hover:underline"
                >
                  Change
                </Box>
              </Flex>
              <BasketTotals checkout={basket} {...props} />
              <BasketDrawerFooter />
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Paragraph marginBottom={3}>
            Something went wrong loading your bag.
          </Paragraph>
          <Button onClick={() => window.location.reload()}>Reload</Button>
        </Box>
      )}
    </>
  );
};

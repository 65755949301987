import { Box } from '../../Box';
import { Center } from '../../Center';
import { Loader } from '../../Loader';

export const BasketLoading = () => {
  return (
    <Box className="flex-1">
      <Center>
        <Loader />
      </Center>
    </Box>
  );
};

import {
  configureStore,
  combineReducers,
  ThunkAction,
  EnhancedStore,
  Action,
  PreloadedState,
} from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { settingsSlice } from './slices/settings.slice';

const rootReducer = combineReducers({
  settingsSlice: settingsSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
});

export const makeStore = (
  preloadedState: PreloadedState<RootState>
): EnhancedStore => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export type AppState = ReturnType<typeof store['getState']>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

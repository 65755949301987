import { Button } from '@/src/packages/components/ui/Button';
import { Flex } from '@/src/packages/components/ui/Flex';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import { useBasketDrawerStore } from '@/src/packages/hooks/use-basket-drawer';

export const BasketEmpty = () => {
  const { handleClose } = useBasketDrawerStore((state) => state);

  return (
    <Flex className="flex-1 flex-col space-y-5">
      <Paragraph className="text-center" marginBottom={6}>
        Your basket is empty.
      </Paragraph>
      <Button variant="solid" size="lg" isBlock onClick={handleClose}>
        Continue shopping
      </Button>
    </Flex>
  );
};
